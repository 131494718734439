import '../styles/global.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { SSRConfig } from 'next-i18next';
import { appWithTranslation } from 'next-i18next';
import { QueryProvider } from '@dx-ui/framework-react-query';
import { queryClientProps } from '../utils/env-vars';
import type { AppProps as NextAppProps } from 'next/app';
import type { DehydratedState } from '@tanstack/react-query';
import { useManualPageLogger } from '@dx-ui/framework-logger';

const APP_NAME = 'dx-offers-ui';

type AppProps = NextAppProps<
  { statusCode: number; dehydratedQueryState: DehydratedState } & SSRConfig
>;

function App({ Component, pageProps, router }: AppProps) {
  useManualPageLogger(router.pathname, APP_NAME);

  if (pageProps.statusCode === 404) {
    return <Component {...pageProps} />;
  }
  return (
    <QueryProvider
      {...queryClientProps}
      appName={APP_NAME}
      dehydratedQueryState={pageProps.dehydratedQueryState}
    >
      <div dir="ltr">
        <Component {...pageProps} />
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryProvider>
  );
}

export default appWithTranslation<AppProps>(App);
